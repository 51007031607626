
import { FarmerTypes } from "@/store/modules/farmers/farmers.types";
import { Component, Ref, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { TraceabilityTypes } from "@/store/modules/traceability/traceability.types";

const farmer = namespace("Farmers");
const traceabilityX = namespace(TraceabilityTypes.MODULE);

@Component({
  components: {
    TraceabilityList: () => import("@/components/coffee/TraceabilityList.vue"),
    UploadCoffeeFarmers: () =>
      import("@/components/coffee/UploadCoffeeFarmer.vue"),
  },
})
export default class TraceabilitiesView extends Vue {
  // @traceability.Mutation(FarmerTypes.SET_ADD_COFFEE_FARMER_DIALOG)
  // public setAddTraceability!: (addTraceability: boolean) => void;

  @farmer.Mutation(FarmerTypes.SET_UPLOAD_COFFEE_FARMER_DIALOG)
  public setUploadCoffeeFarmers!: (uploadFarmer: boolean) => void;

  @traceabilityX.Action(TraceabilityTypes.EXPORT_TRACEABILITIES)
  public exportTraceabilities!: () => void;

  @traceabilityX.State(TraceabilityTypes.EXPORTING_TRACEABILITIES)
  public exportingTraceabilities!: boolean;

  // @farmer.State("upload")
  // public uploadingTraceabilities!: DefaultState;

  public pageSizes = [10, 15, 20];

  public pageSize = 15;

  public query = "";

  @Ref("traceabilityList") public traceabilityListRef!: {
    queryTraceabilities(): void;
  };

  refresh(): void {
    this.traceabilityListRef.queryTraceabilities();
  }

  openAddTraceabilityDialog(): void {
    // this.setAddTraceability(true);
  }

  openUploadTraceabilityDialog(): void {
    this.setUploadCoffeeFarmers(true);
  }
}
